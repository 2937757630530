.Header-root {
  height: 64px;
  width: 100%;
  box-shadow: #00000029 0 1px 6px;
  display: flex;

  .Header-left, .Header-right {
    flex: 0 0 56px;
  }

  .Header-title {
    width: calc(100% - 56px - 56px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .Header-button {
    cursor: pointer;
    width: 56px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    transition: background-color 0.1s ease-in-out;
    padding: 0;

    &:active {
      background-color: var(--yellow-light);
    }
  }
}
