.Modal-root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000B3;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 200;

  &.Modal-active {
    display: flex;
  }

  .Modal-container {
    background-color: var(--white);
    max-width: calc(100% - 56px - 56px); // subtract 56px on each side
  }
}
