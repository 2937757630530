.AddTeams-root {
  height: 56px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--grey-light);
  box-sizing: border-box;

  &.AddTeams-disabled {
    background-color: var(--grey-disabled);
    border-color: transparent;

    p {
      color: var(--grey);
    }
  }

  .AddTeams-icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AddTeams-optional {
    margin-right: 20px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.AddTeams-modal {
  min-width: 200px;

  .AddTeams-modal-row {
    height: 56px;
    border-bottom: 1px solid var(--grey-light);
    display: flex;
    align-items: center;
    transition: background-color 0.1s ease-in-out;

    &:active {
      background-color: var(--yellow-light);
    }

    .AddTeams-modal-checkbox {
      width: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
