@import './colors.scss';
@import url("https://use.typekit.net/beb2dwn.css");
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

body {
  margin: 0;
  font-family: navigo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

button {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

:root {
  --fullvh: calc(var(--vh, 1vh) * 100);
}

@media (min-width: 830px) {
  #mobile-warning {
    display: flex;
  }
}
