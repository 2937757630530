.Activities-installation {
  display: flex;
  align-items: center;
  height: 68px;

  .Activities-installation-icon {
    width: 60px;
    display: flex;
    justify-content: center;
  }

  .Activities-installation-center {
    flex: 1;
  }

  .Activities-installation-button {
    background-color: transparent;
    border: none;
    padding: 0 16px;
    height: 100%;
    outline: none;
    text-decoration: underline;
  }
}

.Activities-root {
  overflow-x: hidden;

  .Activities-body {
    height: calc(var(--fullvh) - 64px - 56px);
    overflow-y: scroll;
    position: relative;
    transition: left 0.2s ease-in-out;
    left: 0;

    &.Activities-swiping {
      left: -50px;
    }

    .Activities-body-spacing {
      height: 56px;
    }

    .Activities-month-root {

      .Activities-month-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-top: 1px solid var(--grey-light);
        border-bottom: 1px solid var(--grey-light);
        margin-bottom: 16px;
      }

      .Activities-day {
        padding: 0 16px;
        display: flex;
        margin-bottom: 24px;

        .Activities-day-date {
          display: flex;
          flex-flow: column;
          align-items: center;
          width: 32px;

          .Activities-day-today {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: var(--primary);
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              color: var(--primary-contrast);
            }
          }
        }

        .Activities-day-activities {
          flex: 1;
          display: flex;
          flex-flow: column;

          .Activities-day-activity {
            display: flex;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            .Activities-day-activity-warning {
              width: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
