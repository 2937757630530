.MobileWarning {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  > :first-child {
    margin-bottom: 24px;
  }

  > :nth-child(4) {
    margin-bottom: 40px;
  }

  > :nth-child(5) {
    margin-bottom: 16px;
  }
}
