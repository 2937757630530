.Input-container {
  display: flex;
  flex-flow: column;
}

.Input-root {
  position: relative;
  cursor: pointer;
  height: 56px;
  border-top: 1px solid var(--grey-light);
  box-sizing: border-box;
  display: flex;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:last-child {
    border-bottom: 1px solid var(--grey-light);
  }

  &:active {
    background-color: var(--yellow-light);
  }

  &.Input-textarea {
    height: 103px;
  }

  .Input-icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .Input-icon-required {
      position: absolute;
      top: 4px;
      right: 8px;
    }
  }

  .Input-optional {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  input, textarea {
    margin-right: 8px;
    cursor: pointer;
    flex: 1;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    font-family: navigo, sans-serif;
    font-size: 14px;
    color: var(--black);

    &::placeholder {
      color: var(--grey);
    }
  }

  textarea {
    resize: none;
    padding: 16px 0;
    height: 70px;
  }

  .Input-warning-icon {
    width: 56px;
    justify-content: center;
    align-items: center;
    display: none;
  }

  &.Input-error {
    background-color: var(--red-light);
    border-color: var(--red);

    &:after {
      content: '';
      width: 100%;
      border-bottom: 1px solid var(--red);
      position: absolute;
      bottom: -1px;
      z-index: 1;
    }

    svg {
      fill: var(--red);
    }

    .Input-warning-icon {
      display: flex;
    }

    input {
      color: var(--red);

      &::placeholder {
        color: var(--red);
      }
    }

    &:active {
      background-color: var(--yellow-light);
    }
  }

  &.Input-disabled {
    background-color: var(--grey-disabled);
    border-color: transparent;
  }

  .Input-password-icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
