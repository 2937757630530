.Text-root {
  margin: 0;

  &.Text-color-black {
    color: var(--black);
  }
  &.Text-color-white {
    color: var(--white);
  }
  &.Text-color-orange {
    color: var(--orange);
  }
  &.Text-color-purple {
    color: var(--purple);
  }
  &.Text-color-green {
    color: var(--green);
  }
  &.Text-color-blue {
    color: var(--blue);
  }
  &.Text-color-red {
    color: var(--red);
  }
  &.Text-color-grey {
    color: var(--grey);
  }

  &.Text-color-primary {
    color: var(--primary);
  }

  &.Text-color-primary-contrast {
    color: var(--primary-contrast);
  }

  &.Text-weight-light {
    font-weight: 300;
  }
  &.Text-weight-normal {
    font-weight: 400;
  }
  &.Text-weight-medium {
    font-weight: 500;
  }
  &.Text-weight-bold {
    font-weight: 700;
  }

  &.Text-uppercase {
    text-transform: uppercase;
  }

  &.Text-font-navigo {
    font-family: navigo, sans-serif;
  }
  &.Text-font-roboto {
    font-family: roboto, sans-serif;
  }
}
