.Navigation-root {
  box-shadow: #00000014 0 -2px 4px;
  height: 56px;
  width: 100%;
  display: flex;
  position: relative;

  .Navigation-button {
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
    background-color: transparent;
    padding: 0;
    border: none;
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    > :first-child {
      margin-bottom: 4px;
    }

    &:active {
      background-color: var(--yellow-light);
    }
  }
}
