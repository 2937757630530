@mixin img-container ($size:100%, $padding:0px) {
  width: calc(#{$size} - #{$padding} * 2);
  height: calc(#{$size} - #{$padding} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $padding;

  img {
    flex-shrink:0;
    -webkit-flex-shrink: 0;
    max-width:100%;
    max-height:100%;
  }
}

.ProfilePicture-root {
  .ProfilePicture-thumbnail {
    margin-right: 12px;
    @include img-container(56px, 1px);
  }
}

.ProfilePicture-full-size {
  @include img-container;
  overflow: hidden;
}
