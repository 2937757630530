.AddAttendees-root {
  min-height: 56px;
  display: flex;
  align-items: center;
  flex-flow: column;
  border-top: 1px solid var(--grey-light);
  transition: background-color 0.1s ease-in-out;

  &:active {
    background-color: var(--yellow-light);
  }

  &:last-child {
    border-bottom: 1px solid var(--grey-light);
  }

  .AddAttendees-title {
    display: flex;
    align-items: center;
    width: 100%;

    .AddAttendees-icon {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .AddAttendees-list {
    width: 100%;

    li:last-child {
      border-bottom: none;
    }
  }
}

.AttendeesList-root {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--fullvh);
  background-color: var(--white);

  &.AttendeesList-visible {
    display: initial;
  }

  .AttendeesList-body {
    height: calc(var(--fullvh) - 64px - 56px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .AttendeesList-group-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      padding-left:8px;
      padding-right:8px;
    }

    .AttendeesList-spacer {
      flex: 1;
    }

    .AttendeesList-group-list {

      .ListItem-root {
        padding-left:0!important;

        .AttendeesList-checkbox {
          width: 56px;
          height: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .AttendeesList-row {
        border-top: 1px solid var(--grey-light);
        display: flex;
        align-items: center;
        height: 56px;
        transition: background-color 0.1s ease-in-out;

        &:active {
          background-color: var(--yellow-light);
        }

        &:last-child {
          border-bottom: 1px solid var(--grey-light);
        }

        .AttendeesList-checkbox {
          width: 56px;
          height: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .AttendeesList-icon {
          margin-right: 16px;
        }
      }
    }
  }
}
