.Login-root {

  .Login-body {
    display: flex;
    flex-flow: column;
    height: calc(var(--fullvh) - 56px);
    overflow-y: scroll;
  }

  .Login-head {
    display: flex;
    flex-flow: column;
    align-items: center;
    min-height: 207px;

    > :first-child {
      margin-top: 47px;
      margin-bottom: 4px;
    }

    > :last-child {
      margin-top: 40px;
    }
  }

  .Login-forgot-password {
    margin-top: 19px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}
