.RequestPasswordReset-root {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: var(--fullvh);
  overflow-y: scroll;
}

.RequestPasswordReset-title {
  border-top: 1px solid var(--grey-light);
  padding: 24px 32px;
}

.RequestPasswordReset-message {
  padding: 24px 32px;
  border-top: 1px solid var(--grey-light);
  border-bottom: 1px solid var(--grey-light);
}

.RequestPasswordReset-input-container {
  border-top: 1px solid var(--grey-light);
  padding: 24px 32px 32px;

  .Input-root {
    border: 1px solid var(--grey-light);
    margin-top: 6px;

    input {
      margin-left: 8px;
    }

    .Input-icon {
      display: none;
    }

    &.Input-error {
      border-color: var(--red);
    }
  }
}

.RequestPasswordReset-submit {
  padding: 16px;
  border-top: 1px solid var(--grey-light);
  border-bottom: 1px solid var(--grey-light);
  display: flex;
  justify-content: flex-end;

  > button {
    width: 157px;
    height: 48px;
    padding: 0 8px;
  }
}

.RequestPasswordReset-icons {
  padding: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .RequestPasswordReset-icons-space {
    width: 50px;
  }

  > :last-child {
    max-width: 50px;
  }
}
