.App-root {
  height: var(--fullvh);
}

.App-overlay-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--fullvh);

  display: flex;
  justify-content: flex-end;
  flex-flow: column;

  #floating-button {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px 16px;
    z-index: 100;

    > div {
      pointer-events: initial;
    }
  }

  .App-overlay-snackbar {
    z-index: 100;

    > div {
      pointer-events: initial;
    }
  }
}
