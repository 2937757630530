.Install-root {
  display: flex;
  flex-flow: column;
  height: var(--fullvh);

  .Install-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    padding: 0;
    z-index: 10;
  }

  .Install-content {
    flex: 1;
  }

  .Install-logo {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 45px;
  }

  .Install-logo-top {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 42px;
  }

  .Install-preview-button {
    border: 1px solid var(--grey-light);
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
  }
}

.InstallAndroidNoSupport {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .InstallAndroidNoSupport-message {
    width: 220px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      margin-bottom: 32px;
    }
  }
}

.InstallAndroidOpera {
  position: relative;

  .InstallAndroidOpera-message {
    padding-top: 106px;
    display: flex;
    flex-flow: column;
    align-items: center;

    > * {
      margin-bottom: 8px;
    }

    .InstallAndroidOpera-arrow {
      position: absolute;
      top: 17px;
      right: 53px;
    }
  }
}

.InstallAndroidSamsung {
  position: relative;

  .InstallAndroidSamsung-message {
    padding-top: 106px;
    display: flex;
    flex-flow: column;
    align-items: center;

    > * {
      margin-bottom: 8px;
    }

    .InstallAndroidSamsung-arrow {
      position: absolute;
      top: 18px;
      right: 50px;
    }

    .Install-preview-button {
      padding: 0 8px;
    }
  }
}

.InstallAndroidFirefox {
  position: relative;

  .InstallAndroidFirefox-message {
    padding-top: 106px;
    display: flex;
    flex-flow: column;
    align-items: center;

    > * {
      margin-bottom: 8px;
    }

    .InstallAndroidFirefox-arrow {
      position: absolute;
      top: 18px;
      right: 69px;
    }

    .Install-preview-button {
      padding: 0 8px;
    }
  }
}

.InstallIOSSafari {
  position: relative;

  .InstallIOSSafari-message {
    display: flex;
    flex-flow: column;
    align-items: center;

    > * {
      margin-bottom: 8px;
    }

    .InstallIOSSafari-arrow {
      position: absolute;
      top: 80px;
      right: 44px;
    }
  }
}
