:root {
  --yellow: #FFEA00;      // button
  --yellow-light: #FFFDE6;// on tap
  --yellow-dark: #F0DD0E; // on button press
  --green: #4CA232;       // success + teambuilding
  --red: #C71A1A;         // error + younited
  --red-light: #F9E8E9;
  --purple: #6A1BB9;      // social
  --orange: #EBA22C;      // friendly
  --blue: #4061ED;        // training
  --white: #FFFFFF;       // background
  --grey-super-light: #FDFDFD; // contrast
  --grey-light: #EBEBEB;  // border
  --grey: #B2B1B1;        // icon + disabled + info + placeholder
  --black: #000000;       // body + icon
  --grey-disabled: #F8F8F8;
}
