.CreateMember-title {
  height: 48px;
  width: 100%;
  padding-left: 8px;
  display: flex;
  border: 1px solid var(--black);
  box-sizing: border-box;
  transition: border-color 0.1s ease-in-out, background-color 0.1s ease-in-out;

  &.CreateMember-disabled {
    background-color: var(--grey-disabled);
    border-color: var(--grey);
  }

  input {
    border: none;
    padding: 0;
    flex: 1;
    color: var(--primary);
    font-family: navigo, sans-serif;
    font-size: 14px;
    font-weight: 700;
    outline: none;
    background-color: transparent;

    &::placeholder {
      color: var(--grey);
      transition: color 0.1s ease-in-out;
    }
  }

  .CreateMember-title-icon {
    width: 40px;
    justify-content: center;
    align-items: center;
    display: none;
  }

  &.CreateMember-title-error {
    border-color: var(--red);
    background-color: var(--red-light);

    input::placeholder {
      color: var(--red);
    }

    .CreateMember-title-icon {
      display: flex;
    }
  }
}

.CreateMember-body {
  height: calc(var(--fullvh) - 64px - 56px);
  overflow-y: scroll;
  position: relative;
}

.CreateMember-modal {
  .CreateMember-modal-warning {
    padding: 16px 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CreateMember-modal-text {
    padding: 0 16px 16px;
  }
}
