.Team-root {
  overflow-x: hidden;

  .Team-body {
    height: calc(var(--fullvh) - 64px - 56px);
    overflow-y: scroll;
    position: relative;
    transition: left 0.2s ease-in-out;
    left: 0;

    &.Team-swiping {
      left: 50px;
    }

    .Team-list-header {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
