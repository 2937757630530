.Button-row {
  display: flex;

  > .Button-root {
    border-right: 1px solid var(--black);
    &:last-child {
      border-right: none;
    }
  }
}

.Button-root {
  padding: 0;
  height: 56px;
  border: none;
  background-color: var(--yellow);
  width: 100%;
  cursor: pointer;

  &:disabled {
    background-color: var(--grey);
  }

  &:active {
    background-color: var(--yellow-dark);
  }

  &.Button-circle {
    border-radius: 28px;
    height: 56px;
    background-color: var(--primary);
    outline: none;
    box-shadow: #00000029 0px 3px 10px;
    position: relative;

    &:active {
      background-image: linear-gradient(#00000010, #00000010);
    }

    .Button-circle-content {
      display: flex;

      &> :first-child {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &> :last-child {
        width: 76px;
        padding-right: 16px;
        text-align: left;
      }
    }
  }
}
