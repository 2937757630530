.Activity-root {
  min-height: 32px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px 0 12px;
  display: flex;

  > * {
    height: 30px;
    display: flex;
    align-items: center;
  }

  &.Activity-white {
    border: 1px solid var(--white);

    &.Activity-solid {
      background-color: var(--white);
    }
  }

  &.Activity-blue {
    border: 1px solid var(--blue);

    &.Activity-solid {
      background-color: var(--blue);
    }
  }

  &.Activity-orange {
    border: 1px solid var(--orange);

    &.Activity-solid {
      background-color: var(--orange);
    }
  }

  &.Activity-green {
    border: 1px solid var(--green);

    &.Activity-solid {
      background-color: var(--green);
    }
  }

  &.Activity-purple {
    border: 1px solid var(--purple);

    &.Activity-solid {
      background-color: var(--purple);
    }
  }

  &.Activity-red {
    border: 1px solid var(--red);

    &.Activity-solid {
      background-color: var(--red);
    }
  }

  .Activity-text {
    display: flex;
    align-items: unset;
    flex-flow: column;
    flex: 1;
    margin-left: 8px;
    min-height: 30px;
    height: unset;

    > :first-child {
      height: 30px;
      display: flex;
      align-items: center;
    }

    > :nth-child(2) {
      margin-bottom: 8px;
    }
  }
}
