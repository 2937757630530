.ListItem-root {
  min-height: 56px;
  display: flex;
  padding-right: 24px;
  transition: background-color 0.1s ease-in-out;
  border-top: 1px solid var(--grey-light);
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &.ListItem-hasAction {
    cursor: pointer;
    &:active {
      background-color: var(--yellow-light);
    }
  }

  &:last-child {
    border-bottom: 1px solid var(--grey-light);
  }

  .ListItem-icon {
    margin-right: 18px;
    margin-top: 20px;
  }

  .ListItem-text {
    margin-top: 18px;
    flex: 1;

    > :nth-child(2) {
      margin: 4px 0 18px;
    }
  }
}