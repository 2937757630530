.PhoneInput-root {
  display: flex;
  border-top: 1px solid var(--grey-light);
  box-sizing: border-box;
  height: 56px;
  transition: background-color 0.1s ease-in-out;

  &:active {
    background-color: var(--yellow-light);
  }

  &:last-child {
    border-bottom: 1px solid var(--grey-light);
  }

  &.PhoneInput-disabled {
    background-color: var(--grey-disabled);
    border-color: transparent;
  }

  .PhoneInput-icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > :last-child {
    flex: 1;
    border: none;

    &:active {
      background-color: unset;
    }

    .Input-icon {
      display: none;
    }
  }

  .PhoneInput-number {
    width: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.PhoneInput-country-picker {
  width: 100vw;
  height: var(--fullvh);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background-color: var(--white);

  &.PhoneInput-country-picker-visible {
    display: initial;
  }

  .PhoneInput-country-picker-filter {
    width: 100%;
    height: 48px;
    display: flex;
    border: 1px solid var(--black);

    input {
      flex: 1;
      outline: none;
      font-size: 16px;
      font-family: navigo, sans-serif;
      font-weight: bold;
    }
  }

  .PhoneInput-country-picker-list {
    overflow-y: scroll;
    height: calc(100% - 64px);

    .PhoneInput-country-picker-row {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid var(--grey-light);
    }
  }
}
