.ProfilePictureInput-root {
  display: flex;
  border-top: 1px solid var(--grey-light);
  box-sizing: border-box;
  height: 56px;
  transition: background-color 0.1s ease-in-out;

  .ProfilePictureInput-input-container {
    display: none;
  }

  .ProfilePictureInput-label {
    flex: 1 1;
    display: flex;
    align-items: center;
  }

  .ProfilePictureInput-delete-button-container {
    margin-left: auto;
    button p {
      margin-left: 22px;
      margin-right: 22px;
      svg {
        font-size: 18px;
      }
    }
  }

  &:active {
    background-color: var(--yellow-light);
  }

  &:last-child {
    border-bottom: 1px solid var(--grey-light);
  }

  &.ProfilePictureInput-disabled {
    background-color: var(--grey-disabled);
    border-color: transparent;
  }

  .ProfilePictureInput-icon {
    min-width: 56px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}
