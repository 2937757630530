.SnackbarRow-wrapper {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  &.SnackbarRow-show {
    height: 48px;
  }
}

.SnackbarRow-root {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  padding-right: 12px;
  box-sizing: border-box;

  &.SnackbarRow-red {
    background-color: var(--red);
  }

  &.SnackbarRow-green {
    background-color: var(--green);
  }

  .SnackbarRow-icon {
    width: 40px;
    display: flex;
    justify-content: center;
  }

  .SnackbarRow-message {
    flex: 1;
  }

  .SnackbarRow-action {
    cursor: pointer;
    margin-left: 12px;
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: var(--white);
  }
}
