.Checkbox-root {
  -webkit-tap-highlight-color: transparent;

  input {
    display: none;
  }

  .Checkbox-box {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: 1px solid var(--grey);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--white);

    svg {
      display: none;
    }
  }

  &.Checkbox-checked {
    .Checkbox-box {
      background-color: var(--primary);
      border: none;

      svg {
        display: initial;
      }
    }

    &.Checkbox-disabled {
      .Checkbox-box {
        background-color: var(--primary);
        opacity: 0.5;
      }
    }
  }

  &.Checkbox-disabled {
    .Checkbox-box {
      background-color: var(--grey);
    }
  }
}
