.Select-root {
  position: relative;
  cursor: pointer;
  height: 56px;
  border-top: 1px solid var(--grey-light);
  box-sizing: border-box;
  display: flex;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:last-child {
    border-bottom: 1px solid var(--grey-light);
  }

  &:active {
    background-color: var(--yellow-light);
  }

  &.Select-disabled {
    background-color: var(--grey-disabled);
    border-color: transparent;
  }

  .Select-icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .Select-icon-required {
      position: absolute;
      top: 4px;
      right: 8px;
    }
  }

  .Select-label {
    flex: 1;
    display: flex;
    align-items: center;
  }

  select {
    display: none;
  }

  .Select-warning-icon {
    width: 56px;
    justify-content: center;
    align-items: center;
    display: none;
  }

  &.Select-error {
    background-color: var(--red-light);
    border-color: var(--red);

    &:after {
      content: '';
      width: 100%;
      border-bottom: 1px solid var(--red);
      position: absolute;
      bottom: -1px;
      z-index: 1;
    }

    svg {
      fill: var(--red);
    }

    .Select-warning-icon {
      display: flex;
    }

    &:active {
      background-color: var(--yellow-light);
    }
  }
}

.Select-list {
  min-width: 200px;
}
