@keyframes ldio-asruc5cikdd-o {
  0% {
    opacity: 1;
    transform: translate(0 0)
  }

  49.99% {
    opacity: 1;
    transform: translate(90%, 0)
  }

  50% {
    opacity: 0;
    transform: translate(90%, 0)
  }

  100% {
    opacity: 0;
    transform: translate(0, 0)
  }
}

@keyframes ldio-asruc5cikdd {
  0% {
    transform: translate(0, 0)
  }

  50% {
    transform: translate(90%, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

.Loader {
  &.Loader-fullSize {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    z-index: 1;
  }

  .ldio-asruc5cikdd div {
    position: absolute;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    top: 30%;
    left: 10%;
  }

  .ldio-asruc5cikdd div:nth-child(1) {
    background: #c71a1a;
    animation: ldio-asruc5cikdd 1s linear infinite;
    animation-delay: -0.5s;
  }

  .ldio-asruc5cikdd div:nth-child(2) {
    background: #ffea00;
    animation: ldio-asruc5cikdd 1s linear infinite;
    animation-delay: 0s;
  }

  .ldio-asruc5cikdd div:nth-child(3) {
    background: #c71a1a;
    animation: ldio-asruc5cikdd-o 1s linear infinite;
    animation-delay: -0.5s;
  }

  .loadingio-spinner-dual-ball-j2ns4s75njr {
    width: 32px;
    height: 32px;
    display: inline-block;
    overflow: hidden;
    background: var(--white);
  }

  .ldio-asruc5cikdd {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }

  .ldio-asruc5cikdd div {
    box-sizing: content-box;
  }
}
