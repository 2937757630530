.Settings-body {
  height: calc(var(--fullvh) - 64px);
  overflow-y: scroll;
  display: flex;
  flex-flow: column;

  .Settings-image {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0;

    img {
      max-width: 80vw;
      max-height: 300px;
      margin-bottom: 24px;
    }

    .Settings-name {
      margin-top: 6px;
    }
  }
}
